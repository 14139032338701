.msgpage .content .content_text{
  flex: 1;
}
.msgpage .info{
  padding: 15px 0;
  justify-content: flex-end;
}

.msgpage .list-group-item .content .img_link{
  width: 10%;
  min-width: 80px;
}
.msgpage  .list-group-item .title{
  line-height: 45px;
  font-size: 16px;
}
.msgpage  .list-group-item .title h6{
  line-height: 45px;
  margin-bottom: 0;
  font-weight: 800;
  overflow:hidden; 
  text-overflow:ellipsis;
  white-space:nowrap;
}
.msgpage .list-group-item .title h6 b{
  color: var(--main-color);
}
.msgpage .list-group-item .is_look{
  position: absolute;
  right: 0;
  top: 50%;
}
.msgpage .list-group-item .is_look .iconfont{
  font-size: 20px;
  color: var(--main-color);
}
.msg {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.msg .all_look_btn, .msg .all_delete_btn{
  margin: 0 10px;
  background-color: var(--main-color);
  color: #fff;
}
.msg-start{
  padding: 0;
  justify-content: flex-start;
}
.msg-start a{
  margin-right: 15px;
  line-height: 2.5;
  position: relative;
  cursor: pointer;
}
.msg-start a:after{
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  left: 0;
  bottom: 0;
  background-color: var(--main-color);
  border-radius: 1px;
}
.msg-start a.active:after{
  width: 100%;
  height: 2px;
}

@media (max-width: 767px) {
  .msgpage  .list-group-item .title{
    font-size: 12px;
  }
  .msg-start{
    margin-left: 15px;
  }
  .msgpage  .list-group-item .title h6{
    font-size: 12px;
  }
}