.reply_form{
  position: relative;
}
.reply_form textarea, .comments_list textarea{
  padding: 0.2rem 0.5rem;
  width: 100%;
  min-height: 54px;
  border-radius: 5px;
  resize: none;
  outline: none;
}
.reply_form .comment_btn{
  padding: .375rem .3rem;
  transition: none;
  box-shadow: none;
  background-color: var(--main-color);
  border-color: var(--main-color);
  font-size: 12px;
}
.reply_form .comment_btn:hover,.reply_form .comment_btn:focus,.reply_form .comment_btn:active{
  background-color: var(--main-color);
  border-color: var(--main-color);
  opacity: .9;
  box-shadow: none;
}
.reply_form .emoji_btns{
  /* padding: 0 10px; */
  display: flex;
  align-items: center;
}
.reply_form .emoji_btns span{
  margin-right: 15px;
  cursor: pointer;
}
.reply_form .emoji_btns span i{
  font-size: 20px;
}
.reply_form .emoji_btns span i.active{
  color: var(--main-color);
}
.reply_form .emoji-mart {
  position: absolute;
  bottom: -320px;
  left: 0;
  z-index: 1;
}
.reply_form .reply_btn{
  color: var(--main-color);
}
.comment_btn_box{
  padding-left: 0;
  padding-right: 0;
}