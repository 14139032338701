@charset "UTF-8";
:root{
  --main-color: #0075c9;
  --main-font-color: #323232;
}
a{
  color: inherit;
}
a:hover{
  text-decoration: none;
  color: inherit;
}
html{
  color: var(--main-font-color);
  height: 100%;
}
body{
  color: var(--main-font-color);
  background: #f4f4f4;
}
h1,h2,h3,h4,h5,h6{
  color: var(--main-font-color);
}
.main{
  margin: 25px auto 60px;
  background-color: #fff;
}
::selection{
  color: #fff;
  background: var(--main-color);
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #333;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
}
.btn-primary{
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  outline: none;
  transition: none;
}
.btn-primary:focus, .btn-primary:hover{
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  outline: none;
  box-shadow: none;
}
select{
  min-width: 100px;
}
.infinite-scroll-component {
  overflow: inherit !important;
}
.ant-message{
  z-index: 1050;
}

/* 点赞+1 */
.hidedz{
  position: absolute;
  top: 0;
  color: var(--main-color);
  opacity: 0;
}
.hidedzanimation{
  animation:dzadd 1s;
  -webkit-animation:dzadd 1s;
  animation-fill-mode:forwards;
}
@keyframes dzadd
{
  from {top:0;opacity: 1}
  to {top:-20px;opacity: 0}
}
@-webkit-keyframes dzadd
{
  from {top:0;opacity: 1}
  to {top:-20px;opacity: 0}
}

@media (max-width: 991px) {
  .main.container{
    width: 100%;
    max-width: 100%;
  }
}