.footer{
  width: 100%;
  background-color: #393b3d;
  color: #a5a5a5;
}
.footer_top{
  padding: 44px 0 48px;
  font-size: 20px;
  line-height: 51px;
  border-bottom: 1px solid #a5a5a5;
  color: #fff;
}
.footer_top img{
  width: 100%;
}
.footer_mid{
  line-height: 108px;
  font-size: 14px;
}
.footer_mid .nav-link{
  padding: 0;
}
.footer_mid img.active{
  display: none;
}
.footer_mid .nav-link:hover img.default{
  display: none;
}
.footer_mid .nav-link:hover img.active{
  display: inline-block;
}
.footer_bottom{
  font-size: 13px;
  line-height: 85px;
}
.footer_bottom .beian{
  margin-bottom: 0;
  font-size: 13px;
}
@media (max-width: 1199px){
  .footer_top{
    padding: 20px 0 10px;
    font-size: 16px;
    line-height: 36px;
  }
  .footer_top img{
    width: 100px;
    margin-bottom: 20px;
  }
}
@media (max-width: 991px){
  .footer .container{
    width: 100%;
    max-width: 100%;
  }
  .footer_top img{
    width: 100px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px){
  .footer_mid{
    padding: 20px 0 35px;
    font-size: 16px;
    line-height: 24px;
  }
}