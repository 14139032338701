.container_wrapper{
  padding: 0 65px;
  border-bottom: 1px solid #f4f4f4;
}
.container_wrapper .navbar{
  padding: 30px 0 40px;
}
.container_wrapper .navbar-nav .nav-link{
  margin: 0;
  font-weight: 400;
}
.container_wrapper .navbar-nav .nav-link.active{
  color: var(--main-color);
  font-weight: 400;
}
.container_wrapper .navbar-nav .nav-link:hover{
  color: var(--main-color);
  font-weight: 400;
}
.container_wrapper .navbar-expand{
  justify-content: space-between;
}
.screening_btn{
  margin-left: 30px;
  cursor: pointer;
  color: #fff;
  width: 60px;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  transition: none;
  border-radius: 14px;
}
.custom-select{
  max-width: 120px;
  padding: 0 2rem 0 .5rem;
  /* text-align: center;
  border-radius: 14px;
  border: none;
  background-color: #f6f6f6; */
  border: 1px solid rgb(118,118,118);
}

.screening_btn:hover, .screening_btn:focus,.screening_btn:active{
  background-color: var(--main-color);
  border-color: var(--main-color);
  box-shadow: none;
}

.react-datepicker-wrapper input{
  border: 1px solid rgb(118,118,118);
  border-radius: .25rem;
}
.react-datepicker-wrapper input:focus{
  border: 1px solid rgb(118,118,118);
  border-radius: .25rem;
}

@media (max-width: 991px) {
  .container_wrapper{
    padding: 0 15px;
  }
  .container_wrapper .navbar{
    flex-wrap: wrap;
  }
  .container_wrapper .navbar .navbar-nav{
    width: 100%;
    margin-bottom: 20px;
  }
  .container_wrapper .navbar .form-inline{
    flex-wrap: wrap;
  }
}