main{
  background-color: #fff;
}
.detail{
  padding-top: 10px;
  overflow: hidden;
}
.detail .title{
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
}
.detail .info{
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail .info p{
  font-size: 15px;
}
.detail .img{
  width: 100%;
}
.detail .img img{
  max-width: 100%;
}
.detail .content{
  padding: 25px 0;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
}
.detail .dz{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail .dz .detail_like{
  display: flex;
  padding: 30px 0;
}
.detail .dz .detail_like span{
  position: relative;
  margin-right: 40px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* .detail .dz .detail_like span.like:hover{
  color: var(--main-color);
} */
.detail .dz .detail_like span.like.is_praise{
  color: var(--main-color);
}
.detail .dz .detail_like span i{
  margin-right: 7px;
  font-size: 20px;
}
.detail .dz .detail_link span{
  position: relative;
  margin-left: 45px;
  display: inline-block;
  width: 32px;
  height: 32px;
  /* overflow: hidden; */
  cursor: pointer;
} 
.detail .dz .detail_link span img{
  width: 100%;
}
.detail .dz .detail_link span .url_link{
  position: absolute;
  right: 0;
  top: -40px;
  width: 240px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  padding: 0 5px;
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
  background-color: #f6f6f6;
  cursor: not-allowed;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: all .4s;
}
.detail .dz .detail_link span .url_link:focus{
  outline: none;
}
.detail .dz .detail_link span:hover .url_link{
  opacity: 1;
  visibility: visible;
}
.detail .comments_list{
  padding-top: 50px;
}
.detail .comments_list .title{
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
}
.detail .comments_list .list-group-item{
  padding: 30px 15px 40px;
  background-color: transparent;
}
.detail .comments_list .list-group-item .comment_top{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail .comments_list .list-group-item .comment_top p{
  font-size: 15px;
}
.detail .comments_list .list-group-item .comment_top p .img_relative{
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
.detail .comments_list .list-group-item .comment_top p img{
  width: 40px;
}
.detail .comments_list .list-group-item .comment_top p .vip{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 13px;
}
.detail .comments_list .list-group-item .comment_content{
  font-size: 15px;
  line-height: 25px;
  color: #888888;
  word-break: break-all;
}
.detail .comments_list .list-group-item .reply_list{
  font-size: 15px;
  line-height: 45px;
  color: var(--main-color);
  cursor: pointer;
}
.detail .comments_list .list-group-item .comment_bottom{
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.detail .comments_list .list-group-item .comment_bottom span{
  position: relative;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.detail .comments_list .list-group-item .comment_bottom span.like.is_praise{
  color: var(--main-color);
}
.detail .comments_list .list-group-item .comment_bottom span i{
  margin-right: 7px;
  font-size: 16px;
}
.detail .comments_list .list-group-item .comment_bottom .reply_btn{
  margin-left: 100px;
  font-size: 14px;
  color: var(--main-color);
}
.reply{
  position: relative;
  padding: 1rem 0;
}
.reply .form_row{
  margin-bottom: 0;
  align-items: flex-end;
}

.comments_list .reply_item{
  padding-right: 15px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 0.5rem;
  font-size: 15px;
  border-bottom: 1px solid #f1f1f1;
  background-color: #f5f5f5;
}
.comments_list .reply_item:last-child{
  border-bottom: none;
}
.comments_list .reply_item .replay_name{
  line-height: 2;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.comments_list .reply_item .replay_name .img_relative{
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
.comments_list .reply_item .replay_name img{
  width: 40px;
}
.comments_list .reply_item .replay_name .vip{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 13px;
}
.comments_list .reply_item .reply_cont{
  line-height: 25px;
}
.comments_list .reply_item .reply_cont span{
  color: var(--main-color);
}
.comments_list .reply_item .reply_cz{
  padding-top: 7px;
  font-size: 14px;
  color: #8a93a0;
  display: flex;
  justify-content: space-between;
  align-items: center;
} 
.comments_list .reply_item .reply_cz span{
  cursor: pointer;
}
.comments_list .reply_item .reply_cz span.like.is_praise{
  color: var(--main-color);
}
.comments_list .reply_item .reply_cz .reply_btn{
  margin-left: 100px;
}
.comment_form{
  position: relative;
}
.comment_form textarea{
  padding: 0.2rem 0.5rem;
  width: 100%;
  min-height: 54px;
  border-radius: 5px;
  resize: none;
  outline: none;
}
.comment_form .comment_btn_box{
  text-align: right;
}
.comment_form .comment_btn{
  margin-right: 15px;
  padding: .375rem 0;
  width: 40%;
  transition: none;
  box-shadow: none;
  background-color: var(--main-color);
  border-color: var(--main-color);
  font-size: 12px;
  box-sizing: border-box;
}
.comment_form .comment_btn:hover,.comment_form .comment_btn:focus,.comment_form .comment_btn:active{
  background-color: var(--main-color);
  border-color: var(--main-color);
  opacity: .9;
  box-shadow: none;
}
.comment_form .emoji_btns{
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.comment_form .emoji_btns span{
  margin-right: 15px;
  cursor: pointer;
}
.comment_form .emoji_btns span i{
  font-size: 20px;
}
.comment_form .emoji_btns span i.active{
  color: var(--main-color);
}
.comment_form .emoji-mart {
  position: absolute;
  bottom: -320px;
  left: 0;
  z-index: 1;
}
.comment_form .reply_btn{
  color: var(--main-color);
}

/* emoji隐藏搜索和说明 */
.emoji-mart-search{display: none;}
.emoji-mart-preview{display: none;}

@media (max-width: 991px) {
  .detail .container{
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 640px) {
  .comment_form .emoji-mart{
    width: 300px !important;
  }
}

/* 侧边 */
.auther_box{
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
  width: 100%;
  padding: 40px 10px 55px;
  border: 1px solid #c8c8c8;
  border-radius: 2px;
  box-shadow:inset 0px 15px 20px -15px #5189f0;
  background-color: #fff;
  z-index: 1;
}
.auther_box .auther_avatar{
  position: relative;
  margin: 0 auto;
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.auther_box .auther_avatar .vip{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20px;
}
.auther_box .auther_name {
  margin-bottom: 0;
  padding: 24px 0;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  text-align: center;
}
.auther_box .auther_infos{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.auther_box .auther_infos .infos_item{
  width: 30%;
}
.auther_box .auther_infos .infos_item h4{
  font-size: 16px;
  font-weight: bold;
  color: var(--main-color);
}
.auther_box .auther_infos .infos_item p{
  margin-bottom: 0;
  font-size: 15px;
  color: #888888;
}

@media (max-width: 1199px) {
  .auther_box{
    position: fixed;
    right: 0;
    top: 50%;
    max-width: 180px;
    transform: translateY(-80%);
  }
}

@media (max-width: 767px) {
  .detail .dz{
    flex-wrap: wrap;
  }
  .detail .dz .detail_like{
    padding: 15px 0;
    width: 100%;
  }
  .detail .dz .detail_link{
    padding-bottom: 15px;
    width: 100%;
  }
  .detail .dz .detail_link .other_link{
    margin-left: 0;
    margin-right: 45px;
  }
  .detail .dz .detail_link span .url_link{
    width: 200px;
    left: 50%;
    margin-left: -100px;
  }
  .auther_box{
    position: fixed;
    padding: 25px 5px;
    max-width: 130px;
  }
  .auther_box .auther_avatar{
    width: 48px;
    height: 48px;
  }
  .auther_box .auther_avatar .vip{
    width: 12px;
  }
  .auther_box .auther_infos .infos_item h4{
    font-size: 14px;
  }
  .auther_box .auther_infos .infos_item p{
    font-size: 13px;
  }
}