.nav_bar {
  height: 83px;
  background: #ffffff;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.blank_tag {
  flex: 0.5;
}
.navbar-nav .nav-link{
  margin: 0 60px;
  font-weight: 700;
}
.navbar-dark .navbar-nav .nav-link{
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  line-height: 83px;
  color: #888888;
}
.navbar-dark .navbar-nav .nav-link:after{
  position: absolute;
  content: '';
  width: 100%;
  left: 0;
  bottom: 0;
  height: 0;
  border-radius: 5px;
  background: var(--main-color);
}

.navbar-dark .navbar-nav .nav-link.active{
  color: var(--main-color);
  font-weight: 700;
}
.navbar-dark .navbar-nav .nav-link.active:after{
  height: 5px;
}
.navbar-dark .navbar-nav .nav-link:hover{
  color: var(--main-color);
  font-weight: 700;
}
.navbar-dark .navbar-nav .nav-link:hover:after{
  height: 5px;
}

.search_btn {
  min-width: 70px;
  margin-left: 25px;
  /* height: 46px; */
  /* width: 100px; */
  cursor: pointer;
  color: #fff;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  transition: none;
  border-radius: 23px;
}
.search_btn:hover, .search_btn:focus,.search_btn:active{
  background-color: var(--main-color);
  border-color: var(--main-color);
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active{
  background-color: var(--main-color);
  border-color: var(--main-color);
  box-shadow: none;
}

.userinfo{
  margin-left: 80px;
  display: flex;
  align-items: center;
  justify-content: left;
}
.avatar{
  display: inline-block;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f1f0f0;
}
.avatar img{
  width: 100%;
}
.username {
  max-width: 80px;
  display: inline-block;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  margin-left: 5px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  cursor: pointer;
}
.signinBtn{
  margin-left: 80px;
  padding: 5px 15px;
  border-radius: 4px;
  background-color: var(--main-color);
  color: #fff;
  cursor: pointer;
}
.form-inline{
  flex-wrap: nowrap;
  justify-content: left;
}
.form-inline .form-control {
  transition: none;
  /* height: 46px; */
  border-color: #e7e7e7;
  border-radius: 23px;
  background-color: #f6f6f6;
}
.form-control:focus{
  border-color: #e7e7e7;
  box-shadow: none;
}
/* # */
.main.container{
  padding-left: 0;
  padding-right: 0;
}
.main.container .row{
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
.user_down_menu p{
  text-align: center;
  cursor: pointer;
}
.user_down_menu p:hover{
  color: var(--main-color);
}

@media (max-width: 1199px) {
  .signinBtn{
    margin-left: 40px;
  }
}

@media (max-width: 991px) {
  .signinBtn{
    margin-left: 0;
    margin-top: 15px;
    display: inline-block;
  }
  .userinfo{
    margin-left: 0;
    margin-top: 15px;
  }
  .nav_bar .container{
    width: 100%;
    max-width: 100%;
  }
  .nav_bar .navbar-brand img{
    width: 80px;
  }
  .navbar-dark .navbar-toggler-icon{
    background-image: url('/src/assets/images/nav_bg.png');
  }
  .nav_bar .navbar-collapse{
    position: absolute;
    padding: 0 15px 20px;
    top: 83px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 2;
    box-sizing: border-box;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  }
  .nav_bar .navbar-nav{
    padding-bottom: 25px;
  }
  .nav_bar .navbar-nav .nav-link{
    margin: 0;
    line-height: 40px;
  }
  .navbar-dark .navbar-nav .nav-link:after{
    width: 0;
  }
  .nav_bar .navbar-collapse .username{
    display: block;
    margin-top: 0;
    margin-left: 5px;
    line-height: 30px;
  }
}
