.editor_page input.title{
  border: 1px solid rgb(118,118,118);
}
.editor_page .w-e-toolbar{
  border: 1px solid rgb(118, 118, 118) !important;
  border-bottom: 1px solid #eee !important;
  border-radius: .3rem .3rem 0 0;
}
.editor_page .w-e-text-container{
  border: 1px solid rgb(118, 118, 118) !important;
  border-radius: 0 0 .3rem .3rem;
}
.editor_page .ant-upload.ant-upload-select-picture-card{
  border: 1px solid rgb(118, 118, 118);
  border-radius: .3rem;
}
.editor_page .ant-upload.ant-upload-select-picture-card > .ant-upload{
  font-size: 30px;
}
.editor_page .custom-select{
  max-width: 100%;
}
@media (max-width: 991px){
  .editor_page{
    padding: 0 15px;
  }
  .editor_page .custom-select{
    max-width: 100%;
  }
}
@media (max-width: 767px){
  .editor_page .custom-select{
    margin: 10px 0;
  }
}