.container_wrapper.forum{
  padding-bottom: 30px;
}
.container_wrapper .navbar{
  padding: 30px 0;
}

@media (max-width: 991px){
  .container_wrapper .navbar .froum_navbar_nav{
    width: auto;
  }
  .container_wrapper .form-inline{
    flex-wrap: wrap;
  }
}