.msginfo .jumbotron h4{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.msginfo .jumbotron h4 img{
  width: 60px;
  margin-right: 5px;
}
.msginfo .jumbotron h4 a{
  color: var(--main-color);
}
.msginfo .jumbotron .remake{
  padding: 50px 0;
}
.msginfo .jumbotron .see{
  background-color: var(--main-color);
  color: #fff;
  display: inline-block;
  border-radius: 5px;
}
.msginfo .jumbotron .info{
  padding-right: 50px;
  display: flex;
  justify-content: flex-end;
}
.msginfo .jumbotron .info span{
  cursor: pointer;
}
.msginfo .jumbotron .info .iconfont{
  font-size: 20px;
}