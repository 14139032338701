.container_wrapper{
  padding: 0 65px;
  border-bottom: 1px solid #f4f4f4;
}
.list-group{
  padding-bottom: 60px;
}
.list-group-item{
  border: none;
  padding: 0 65px;
  border-bottom: 1px solid #f4f4f4;
}
.list-group-item .title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 65px;
  font-size: 18px;
}
.list-group-item .title h5{
  padding-right: 2%;
  flex: 1;
  font-weight: 700;
  overflow: hidden;
}
.list-group-item .title h5 a{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-group-item .content{
  display: flex;
  justify-content: left;
  align-items: flex-start;
}
.list-group-item .content .img_link{
  width: 18%;
  min-width: 100px;
}
.list-group-item .content .img{
  margin-right: 15px;
  width: 100%;
  height: 90px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-group-item .content .img img{
  max-width: 100%;
  max-height: 100%;
}
.list-group-item .content p{
  flex: 1;
  font-size: 15px;
  line-height: 25px;
  color: #888888;
}
.list-group-item .content p.comm_content{
  padding: 10px;
  border: 1px solid #f1f1f2;
} 

.list-group-item .content p span{
  color: var(--main-color);
  cursor: pointer;
}
.list-group-item .content .cont_link{
  padding: 0;
}
.list-group-item .content .cont_link .cont{
  color: #888888;
}
.list-group-item .info{
  display: flex;
  padding: 30px 0;
}
.list-group-item .info span{
  position: relative;
  margin-right: 40px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.list-group-item .info span i{
  margin-right: 10px;
  font-size: 20px;
}
/* .list-group-item .info span.like:hover{
  color: var(--main-color);
} */
.list-group-item .info span.like.is_praise{
  color: var(--main-color);
}

@media (max-width: 991px) {
  .list-group-item{
    padding: 0 15px;
  }
  .list-group-item .content .cont_link .cont p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .list-group-item .content .img_link{
    padding: 10px;
  }
}

  